body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.app-wrapper {
    display: flex;
    height: 100vh;
    overflow: hidden;
}
.content-wrapper{
    flex: 1;
    overflow-y: auto;
    margin-left: -20px;
    padding-left: 20px;
}

svg{
    display: block;
}

.kl-container{
    width: 100%;
    padding: 30px 55px 50px 43px;
}

.dropdown-item.disabled, .dropdown-item:disabled{
    background-color: #f3f3f3a6 !important;
    pointer-events:all !important;
    cursor: not-allowed !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-item.disabled::after, .dropdown-item:disabled::after{
    position: relative;
    top: 3px;
    right: 0px;    
    content: url("/src/assets/icons/check.svg");
    display: inline-block;
    width: 30px; 
    min-width: 30px;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgba(197, 204, 201, 0.1);
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: rgba(197, 198, 204, 0.1);
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #387480;
}

.mark, mark{
    padding: 0px !important;
    color: white !important;
   background-color: #387480 !important;
}

.dropdown-menu {
    z-index: 1000000 !important; 
}

.mt-75{
    margin-top: 75px;
    overflow: hidden;
}

.mt-100{
    margin-top: 200px;
    overflow: hidden;
}