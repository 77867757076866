// font-family: 'Bai Jamjuree', sans-serif;
// font-family: 'DM Sans', sans-serif;
// font-family: 'Inter', sans-serif;
// font-family: 'Roboto', sans-serif;

@import url('https: //fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;600;700&family=DM+Sans:opsz@9..40&family=Inter:wght@400;500;700;800&family=Lato:wght@400;700&family=Roboto:wght@400;500;700&display=swap');


$inter: 'Inter', Helvetica, sans-serif;
$dm-sans: 'DM Sans', sans-serif;
$bai-jam: 'Bai Jamjuree', sans-serif;
$rob: 'Roboto', sans-serif;
$lot: 'Lato', sans-serif;



$primary: #213F4D;
$secondary: #387480;
$yellow: #FFB700;
$red: #FF6363;
$blue: #006FFD;
$green: #88B88A;
$grey: #A0A0A0;
$white: #ffffff;