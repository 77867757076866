@import "../../assets/styles/variable";

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    button {
        padding: 5px 20px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid rgba(197, 198, 204, 0.40);
        background: $secondary;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.05);
        color: $white;
        text-align: center;
        font-family: $inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner-border.spinner-border-sm {
            margin-right: 7px;
            min-width: 16px;
        }

        .button-icon {
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            i {
                flex: 0 0 24px;
                width: 24px;
                height: 24px;
                margin-right: 7px;

                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                }

                &:hover {
                    color: $white ;
                }
            }

            &:hover .button-icon {
                background: transparent;
            }
        }
    }

    .edit-btn,
    .add-btn,
    .month-btn,
    .week-btn {
        &:hover {
            background: rgba(56, 116, 128, 0.8) !important;
            border: 1px solid $secondary;
        }
        
        &:disabled {
            background: rgba(56, 116, 128, 0.8) !important;
            border: 1px solid $secondary;
        }

        &:active {
            background: $secondary !important;
            border-color: $secondary !important;
        }
    }

    .delete-btn {
        .button-icon {
            .button-icon {
                display: block;
                justify-content: flex-start;
                align-items: center;
                margin-right: 10px;
            }

            i {
                flex: 0 0 6px;
                width: 6px;
                height: 11px;
                color: #000;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &:hover {
            background: #ffb700cc;
            border: 1px solid #ffb700cc;
            ;
        }

        &:active {
            background: #ffb700cc;
            border: 1px solid #ffb700cc;
        }
    }

    .remove-btn {
        &:hover {
            background: transparent !important;
            border: none !important
        }

        &:active {
            background: transparent !important;
            border: none !important;
        }
    }

    .remove-btn-contracts {
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }

    .delete-icon-btn {
        color: #D9D9D9;
        font-family: $inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        background: transparent !important;
        box-shadow: none !important;
        border: none !important;

        i {
            &:hover {
                color: #D9D9D9 !important;
            }
        }

        &:hover {
            border: none !important;
            background: transparent !important;
            color: #D9D9D9;
        }

        &:active {
            border: none !important;
            background: transparent !important;
            color: #D9D9D9;
        }
    }

    .confirm-delete {
        &:hover {
            border: none !important
        }

        &:active {
            border: none !important;
        }

    }
}