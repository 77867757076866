@import "../../assets/styles/variable";

/* Sidebar.css */
.sidebar {
    max-width: 96px;
    flex: 0 0 96px;
    height: 100vh;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0px 20px 20px 0px;
    background: $secondary;
    padding: 13px;
    position: relative;
    z-index: 3;

    .sidebar-logo {
        margin: 0px;
        display: block;
        padding: 49px 0px 39px;
        border-bottom: 1px solid $white;
        text-align: center;
        color: $primary;

        i {
            width: 39px;
            height: 18px;
            display: inline-flex;
        }

        &.active {
            color: $white;
        }
    }
}

.sidebar-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    a {
        padding: 10px;
        color: $primary;

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        i {
            height: 26px;
            width: 26px;
            display: block;

            svg {
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        &.active {
            color: $white;
        }
    }
}

/* Rest of the styles */