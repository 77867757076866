@import "../../assets/styles/variable";

.loader-tr {
    .spinner-block {
        height: calc(100vh - 295px) !important;
    }
}

.loader-tr {
    border: none;
    outline: none;

    &:hover {
        background-color: transparent !important;
    }

    td {
        border: none !important;
    }

    .spinner-block {
        height: calc(100vh - 280px) !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
}

.spinner-small-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.spinner-div {
    display: flex;
    width: 100%;
    height: 50vh;
    justify-content: center;
    align-items: center;

    .spinner-border {
        width: 50px !important;
        height: 50px !important;
    }
}