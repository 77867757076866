@import "../../assets/styles/variable";

.address-loading{
    position: absolute;
    top: 0;
    z-index: 10;
    min-width: calc(100% - 90px);
    left: 75px;
    .tile{
        background-color: #D9D9D9;
        display: flex;
        align-items: flex-start;
        padding: 10px 20px;
        justify-content: space-between;
        align-items: center;   
    }
}

.address-loading-tile{
    position: absolute;
    top: 0;
    z-index: 10;
    min-width: calc(100% - 90px);
    left: 75px;
    .tile{
        background-color: #D9D9D9;
        display: flex;
        align-items: flex-start;
        padding: 10px 20px;
        justify-content: space-between;
        align-items: center;

        .buttons{
            .button-wrapper{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                align-items: center;
                flex-shrink: 0;
            }
        }

        .success-message{
            font-family: $inter;
            font-size: 22px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0px;
            text-align: left;
            color: $secondary;
        }

        .count-details{
            .count{
                font-family: $inter;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
            }
        }
    }
    .buttons{
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;

        .button-tooltip{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            i{
                background-color: white;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg{
                    fill: #231F20;
                }
            }
        }
        
        .edit-btn{
            width: 263px;
            height: 53px;
            top: 43px;
            left: 789px;
            border-radius: 47px;
            background-color: #FFFFFF !important;
            color: #231F20;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 40px;
            border: none !important;

            &:hover{
                background-color: #FFFFFF !important;
                color: #231F20;
            }

            &:focus{
                background-color: #FFFFFF !important;
                color: #231F20;
            }
        }

        .close-btn{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.step-tooltip {    
    .tooltip-arrow::before{
        border-left-color: #387480 !important;
    }
   
    .tooltip-inner {
        background-color: #387480;
        color: white;
        min-width: 300px;
    }

    .custom-step-tooltip{   
        padding-bottom: 1px;
        padding-top: 15px;
        .tooltip-list{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            li{
                text-align: start;
            }
        }
        
    }
}